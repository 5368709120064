import PropTypes from "prop-types";

const TeamMemberProps = PropTypes.shape({
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  biography: PropTypes.shape({
    biography: PropTypes.string
  })
});

export default TeamMemberProps;
