import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import ButtonModal from "../ButtonModal";
import Modal from "../Modal";
import { H2 } from "../Type";
import styles from "./style.module.scss";

const GarmentImage = ({ image, title }) => {
  if (!image) return null;
  return (
    <ButtonModal
      Button={({ onClick }) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events
        <a onClick={onClick} role="button" tabIndex={0}>
          <Image
            fluid={image.fluid}
            className={styles.garmentImage}
            title={`Handmade bespoke ${title}`}
          />
        </a>
      )}
      Modal={({ modalVisible, onClose }) => (
        <Modal visible={modalVisible} onClose={onClose} name={title}>
          <div className={styles.modal}>
            <H2>Bespoke {title}</H2>
            <Image fluid={image.fluid} title={`Handmade bespoke ${title}`} />
          </div>
        </Modal>
      )}
    />
  );
};

GarmentImage.propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string
    })
  }).isRequired,
  title: PropTypes.string.isRequired
};

export default GarmentImage;
