import PropTypes from "prop-types";

const ServiceProps = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  heroImage: PropTypes.shape({
    fixed: PropTypes.shape({
      src: PropTypes.string
    })
  }),
  description: PropTypes.shape({
    description: PropTypes.string
  })
};

export default ServiceProps;
