import PropTypes from "prop-types";

const BespokeStepProps = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    description: PropTypes.string
  }).isRequired
};

export default BespokeStepProps;
