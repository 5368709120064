import React from "react";
import PropTypes from "prop-types";
import GarmentsListItem from "./GarmentListItem";
import { GarmentProps } from "../../proptypes";
import style from "./style.module.scss";

const GarmentsList = ({ garments, priceMap }) => (
  <div className={style.garment}>
    {garments.map(garment => (
      <GarmentsListItem
        key={garment.id}
        {...garment}
        prices={priceMap[garment.name]}
      />
    ))}
  </div>
);

GarmentsList.propTypes = {
  garments: PropTypes.arrayOf(PropTypes.shape(GarmentProps)),
  priceMap: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        garmentName: PropTypes.string,
        garmentPrice: PropTypes.number
      })
    )
  ).isRequired
};

GarmentsList.defaultProps = {
  garments: []
};

export default GarmentsList;
