import React from "react";
import PropTypes from "prop-types";
import { quoteBox } from "./style.module.scss";

const QuoteBox = ({ children }) => (
  <div className={quoteBox}>
    <span>{children}</span>
  </div>
);

QuoteBox.propTypes = {
  children: PropTypes.node.isRequired
};

export default QuoteBox;
