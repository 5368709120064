import PropTypes from "prop-types";

const GarmentProps = {
  id: PropTypes.string,
  name: PropTypes.string,
  mainImage: PropTypes.shape({
    fixed: PropTypes.shape({
      src: PropTypes.string
    })
  }),
  shortDescription: PropTypes.shape({
    shortDescription: PropTypes.string
  })
};

export default GarmentProps;
