import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { get, has, map, reduce, pipe } from "lodash/fp";
import SEO from "../../components/SEO";
import IntroHeader from "../../components/IntroHeader";
import GarmentsList from "../../components/GarmentsList";
import QuoteBox from "../../components/QuoteBox";
import { Container, Section } from "../../components/LayoutUtils";
import Layout from "../../layouts/index";
import getPageImage from "../../utils/getPageImage";

const priceMap = pipe(
  get("edges"),
  map("node"),
  reduce((memo, price) => {
    const product = price.product.name;
    if (has(product, memo)) {
      memo[product].push(price);
      return memo;
    }
    return { ...memo, [product]: [price] };
  }, {})
);

const GarmentsPage = ({ data }) => {
  const page = data.contentfulPage;
  const products = data.allContentfulProduct.edges;
  const prices = priceMap(data.allContentfulGarmentPrice);
  return (
    <Layout>
      <SEO
        image={getPageImage(page)}
        title="Our products"
        description="All of our bespoke garments are handmade with a full floating canvas, right here in the Square Mile."
      />
      <IntroHeader {...page} />
      <Container>
        <Section>
          <GarmentsList
            garments={products.map(get("node"))}
            priceMap={prices}
          />
          <div className="row">
            <div className="offset-lg-1 col-sm-12 col-lg-10">
              <QuoteBox>
                The price of a bespoke garment will vary depending on the cloth
                you choose.{" "}
                <Link to="/pricing">Find out more about our prices</Link>.
              </QuoteBox>
            </div>
          </div>
        </Section>
      </Container>
    </Layout>
  );
};

GarmentsPage.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({})
  }).isRequired
};

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "garments" }) {
      ...page
    }
    allContentfulGarmentPrice(sort: { fields: [price], order: ASC }) {
      edges {
        node {
          id
          garmentName
          price
          product {
            name
          }
        }
      }
    }
    allContentfulProduct(sort: { fields: [displayOrder], order: ASC }) {
      edges {
        node {
          name
          subtitle
          id
          slug
          shortDescription {
            shortDescription
          }
          mainImage {
            id
            fluid(maxWidth: 400) {
              ...fluidImage
            }
          }
        }
      }
    }
  }
`;

export default GarmentsPage;
