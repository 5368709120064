import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import Markdown from "../Markdown";
import GarmentImage from "./GarmentImage";
import { GarmentProps } from "../../proptypes";
import style from "./style.module.scss";

const GarmentsListItem = ({ name, mainImage, shortDescription, slug }) => (
  <div className={classNames(style.garment, "row")}>
    <div className="offset-lg-1 col-sm-3 col-lg-3">
      <GarmentImage image={mainImage} title={name} />
    </div>
    <div className="col-sm-9 col-lg-7">
      <h2>{name}</h2>
      <Markdown source={shortDescription.shortDescription} />
      {slug ? <Link to={`/garments/${slug}`}>More</Link> : null}
    </div>
  </div>
);

GarmentsListItem.propTypes = {
  ...GarmentProps
};

GarmentsListItem.defaultProps = {
  prices: []
};

export default GarmentsListItem;
